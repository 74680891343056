<template>
  <div>
    <transition name="fade">
      <CCard v-if="show" color="">
        <CCardHeader>
          Student Class Map
          <div class="card-header-actions">
            <CLink
              class="card-header-action btn-minimize"
              @click="isCollapsed = !isCollapsed"
            >
              <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`" />
            </CLink>
            <CLink
              href="#"
              class="card-header-action btn-close"
              v-on:click="show = false"
            >
              <CIcon name="cil-x" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="isCollapsed" :duration="400">
          <CCardBody>
            <form>
             

            

              <div class="form-group row">
                <label
                  for="divisionFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Select Student </label
                >
                <div class="col-sm-8">
                  <select class="form-control" v-model="form.school_id">
                    <option value disabled hidden>Select Student</option>
                    <option
                        v-for="(y,i) in student_list"
                        v-bind:key="i"
                        :value="y.student_id"
                    >{{y.student_name}}</option>
                  </select>
                </div>
              </div>

               <div class="form-group row">
                <label
                  for="divisionFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Select Class </label
                >
                <div class="col-sm-8">
                  <select class="form-control" v-model="form.class_id">
                    <option value disabled hidden>Select Class</option>
                    <option
                        v-for="(y,i) in class_list"
                        v-bind:key="i"
                        :value="y.class_id"
                    >{{y.class_standard}}</option>
                  </select>
                </div>
              </div>

               <div class="form-group row">
                <label
                  for="divisionFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Academic Year </label
                >
                <div class="col-sm-8">
                  <select class="form-control" v-model="form.year_id">
                    <option value disabled hidden>Select Year</option>
                    <option
                        v-for="(y,i) in year_list"
                        v-bind:key="i"
                        :value="y.ac_year_id"
                    >{{y.ac_year_description}}</option>
                  </select>
                </div>
              </div>

               <div class="form-group row">
                <label
                  for="divisionFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Select Division </label
                >
                <div class="col-sm-8">
                  <select class="form-control" v-model="form.division_id">
                    <option value disabled hidden>Select Division</option>
                    <option
                        v-for="(y,i) in division_list"
                        v-bind:key="i"
                        :value="y.division_id"
                    >{{y.division}}</option>
                  </select>
                </div>
              </div>
              <!-- end form-group -->

             
              <div class="form-group row">
                <div class="col-sm-8 offset-sm-4">
                  <button type="submit" class="btn btn-primary mr-3 text-white" @click="add_student">
                    Submit
                  </button>
                  <button type="submit" class="btn btn-light">
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </CCardBody>
        </CCollapse>
      </CCard>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import apis from '@/apis';
const base_url=process.env.VUE_APP_BASE_URL;


export default {
  name: "AddTeacher",
  components: {},
  data: function() {
    return {
      show: true,
      isCollapsed: true,
      class_list:[],
      student_list:[],
      year_list:[],
      division_list:[],
      teacher:"",
      class_id:"",
      division:"",
      year:"",
      subject:"",
       form: {
        student:'',
        class_id:'',
        division_id:'',
        year_id:'',
       
      },

    };
  },
  methods:{
    get_student(){
      var promise = apis.list_Students().then( response => {
          this.student_list = response.data;
        //  console.log(response);
        }).catch( error => {

        });
    },

    get_year(){
      var promise = apis.list_AcademicYears().then( response => {
          this.year_list = response.data;
        //  console.log(response);
        }).catch( error => {

        });
    },

    get_class(){
      var promise = apis.list_ClassMasters().then( response => {
          this.class_list = response.data;
        //  console.log(response);
        }).catch( error => {

        });
    },
    get_division(){
      var promise = apis.list_DivisionMasters().then( response => {
          this.division_list = response.data;
         // this.totalRows = this.student_items.length;
        //  console.log(response);
        }).catch( error => {

        });
    },
  
  },
  created(){
    this.get_student();
    this.get_class();
    this.get_division();
    this.get_year();
  }
};
</script>
